import gql from 'graphql-tag'

export const AgentUpdateFragment = gql`
  fragment AgentUpdate on Agent {
    name
    displayName
    description
    systemPromptTemplate
    functions
    model
    modelTemperature
    modelTopP
    modelPresencePenalty
    modelFrequencyPenalty
    knowledgeBase
    kbMax
    kbThreshold
  }
`

export const AgentCreateFragment = gql`
  fragment AgentCreate on Agent {
    _id
    environmentId
  }
`

export const AgentFragment = gql`
  fragment Agent on Agent {
    ...AgentCreate
    ...AgentUpdate
  }

  ${AgentCreateFragment}
  ${AgentUpdateFragment}
`
